import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Header } from 'components';
import { Layout, Container } from 'layouts';

const About = center => (
  <Layout>
    <Helmet title={'About Page'} />
    <Header title="About Page"></Header>
    <Container>
      <p>
        Hi, I am Yakuza and welcome to my website. I am glad that you are able to stumble upon my small website. It's a pleasure to have visitors. 
        <br/>
        <br/>
        In case you are wondering, I am not a member or have any association with the Yakuza(Japanese mafia). This is just my alias that I like to use for my online presence.
        <br/>
        <br/>
        Since I like helping people, I created this website so I can reach out to everyone and share some interesting things in the world.
        <br/>
        <br/>
        Thank you and I hope love my website.
        <br/>
        <br/>
        ~ Yakuza
      </p>
    </Container>
  </Layout>
);

export default About;

Container.propTypes = {
  center: PropTypes.object,
};
